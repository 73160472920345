import { firebase } from '../firebase.js' // no tocar
import { getAuth, onAuthStateChanged, setPersistence, browserLocalPersistence, signOut } from "firebase/auth"
import { getDatabase, ref, onValue} from 'firebase/database'

export default {
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    async getCurrentUser({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let auth = getAuth()
          onAuthStateChanged(auth, (user) => {
            if (user){
              return resolve({
                resultado: 1,
                msj: 'OK',
                usuario: user,
                auth: auth
              })
            }else{
              // desloguear
              return resolve({
                resultado: 2,
                msj: 'No se encontró usuario logueado',
                usuario: null,
                auth: auth
              })
            }
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getCurrentUser: ' + error.message,
            usuario: null,
            auth: null
          })
        }
      })
    },
    async setPersistenceUser({}){
      return await new Promise(async (resolve, reject) => {
        try{
          const auth = getAuth()
          setPersistence(auth, browserLocalPersistence)
          .then(() => {
            return resolve({
              resultado: 1,
              msj: 'OK'
            })
          })
          .catch((error) => {
            // Handle Errors here.
            return resolve({
              resultado: 0,
              msj: 'Ocurrio un problema al setear persistencia: ' + error.message
            })
          });
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo setPersistenceUser: ' + error.message
          })
        }
      })
    },
    async verifyUserXEmpresa({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let data = []
          let db = getDatabase()
          let usuarioPeticion = ref(db, 'usuarios/' + datos.uid)
          onValue(usuarioPeticion, (snapshot) => {
            data = snapshot.val()
            if (data.hasOwnProperty('empresa')){
              let existe = 0
              for (let id in data.empresa){
                if (data.empresa[id].codigo == datos.empresa){
                  existe = 1
                }
              }
              if (existe == 0){
                // no tiene habilitada la empresa
                return resolve({
                  resultado: 2,
                  msj: 'No habilitado para la empresa.'
                })
              }else{
                // ok
                return resolve({
                  resultado: 1,
                  msj: 'OK'
                })
              }
            }else{
              return resolve({
                resultado: 2,
                msj: 'No habilitado para la empresa.'
              })
            }
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo verifyUserXEmpresa: ' + error.message
          })
        }
      })
    },
    async executeLogOut({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let auth
          if (datos.hasOwnProperty('auth')){
            auth = datos.auth
          }else{
            auth = getAuth()
          }
          signOut(auth)
            .then(() => {
              return resolve({
                resultado: 1,
                msj: 'OK'
              })
            })
            .catch((error) => {
              return resolve({
                resultado: 0,
                msj: 'No se pudo desloguear: ' + error.message
              })
            })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo executeLogOut: ' + error.message
          })
        }
      })
    },
    async getEmpresaInfo({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let data = []
          let db = getDatabase()
          let usuarioPeticion = ref(db, 'empresas/' + datos.empresa)
          onValue(usuarioPeticion, (snapshot) => {
            data = snapshot.val()
            return resolve({
              resultado: 1,
              msj: 'OK',
              empresa: data
            })
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getEmpresaInfo: ' + error.message,
            empresa: null
          })
        }
      })
    },
  }
}