<template>
  <v-app-bar
    color="primary"
    style="z-index: 2"
    :flat="$vuetify.breakpoint.mdAndUp"
    dense
    dark
    app
  >
    <v-app-bar-nav-icon
      @click.stop="$vuetify.breakpoint.smAndDown ? drawer = !drawer : expand = !expand"
    ></v-app-bar-nav-icon>
    <v-spacer></v-spacer>
    <v-tooltip bottom>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          v-bind="attrs"
          v-on="on"
          icon
          @click="logout"
        >
          <v-icon>fas fa-sign-out-alt</v-icon>
        </v-btn>
      </template>
      <span>Cerrar sesión</span>
    </v-tooltip>
  </v-app-bar>
</template>

<script>
  import router from '../../router'
  import {mapActions} from 'vuex'
export default {
  props: {
    p_expand: Boolean,
    p_drawer: Boolean,
  },
  computed: {
    expand: {
      get () {
        return this.p_expand
      },
      set (value) {
        this.$emit('update:p_expand', value)
      }
    },
    drawer: {
      get () {
        return this.p_drawer
      },
      set (value) {
        this.$emit('update:p_drawer', value)
      }
    },
  },
  methods: {
    ...mapActions(['logOutFirebase']),
    async logout(){
      this.$store.state.modalCarga = true
      await this.logOutFirebase({})
    },
  },
}
</script>

<style>

</style>