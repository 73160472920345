export function convertDecimals(numero){
  return parseFloat(parseFloat(numero.toString().replace(',', '.')).toFixed(2).toString().replace(',', '.'))
}

export function cadenaVacia(cadena){
  if (cadena == null || cadena == undefined || cadena.toString().length == 0) return true
  else return false
}

export function roundNumber(num, scale) {
  num = num.toString().replace(',', '.');
  if(!("" + num).includes("e")) {
      return +(Math.round(num + "e+" + scale)  + "e-" + scale);
  } else {
      var arr = ("" + num).split("e");
      var sig = ""
      if(+arr[1] + scale > 0) {
          sig = "+";
      }
      return +(Math.round(+arr[0] + "e" + sig + (+arr[1] + scale)) + "e-" + scale);
  }
}