import store from '../store'
import { firebase } from '../firebase.js' // no tocar
import { getDatabase, ref, onValue, query, startAt, orderByChild} from 'firebase/database'
import config from '../../config'
import {convertDecimals} from '../util/util'
export default{
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    async getEstadosCobro({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let data = []
          let listado = []
          let db = getDatabase()
          let cobrosPeticion = ref(db, 'cobros_estados')
          onValue(cobrosPeticion, (snapshot) => {
            data = snapshot.val()
            for (let id in data){
              let unObj = {
                idFirebase: id,
                descripcion: data[id].descripcion,
                estado_FD: data[id].estado_FD,
                estado_decidir: data[id].estado_decidir,
                estado_inhabilita: data[id].estado_inhabilita,
                link_accesible: data[id].link_accesible,
                tiene_error: data[id].tiene_error
              }
              listado.push(unObj)
            }
            return resolve({
              resultado: 1,
              msj: 'OK',
              estados: listado
            })
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getEstadosCobro: ' + error.message,
            estados: null
          })
        }
      })
    },
    async getLinksPagoEmpresa({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          /*let data = []
          let db = getDatabase()
          let cobrosPeticion = ref(db, 'boton_pago/' + datos.empresa)
          onValue(cobrosPeticion, (snapshot) => {
            data = snapshot.val()
            for (let id in data){
              data[id].keyFirebase = id
            }
            console.log({
              resultado: 1,
              msj: 'OK',
              links: data
            })
            return resolve({
              resultado: 1,
              msj: 'OK',
              links: data
            })
          })*/
          let linksPeticion
          if (datos.fecha_hasta.toString().length > 0){
            linksPeticion = await fetch(config.URL_CELUSHOP + `boton_pago/${datos.empresa}.json?orderBy="fecha_log"&startAt="${datos.fecha_desde}"&endAt="${datos.fecha_hasta + ' 23:59:59'}"`)
          }else{
            linksPeticion = await fetch(config.URL_CELUSHOP + `boton_pago/${datos.empresa}.json?orderBy="fecha_log"&startAt="${datos.fecha_desde}"`)
          }
          let links = await linksPeticion.json()
          for (let id in links){
            links[id].keyFirebase = id
          }
          return resolve({
            resultado: 1,
            msj: 'OK',
            links: links
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getLinksPagoEmpresa: ' + error.message,
            links: null
          })
        }
      })
    },
    async getFinanciaciones({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let data = []
          let db = getDatabase()
          let financPeticion = ref(db, 'empresas/' + datos.empresa + '/financiaciones')
          onValue(financPeticion, (snapshot) => {
            data = snapshot.val()
            for (let id in data){
              data[id].keyFirebase = id
            }
            return resolve({
              resultado: 1,
              msj: 'OK',
              financiaciones: data
            })
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getFinanciaciones: ' + error.message,
            financiaciones: null
          })
        }
      })
    },
    async getTarjetas({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let data = []
          let db = getDatabase()
          let tarjetasPeticion = ref(db, 'tarjetas')
          onValue(tarjetasPeticion, (snapshot) => {
            data = snapshot.val()
            for (let id in data){
              data[id].keyFirebase = id
            }
            return resolve({
              resultado: 1,
              msj: 'OK',
              tarjetas: data
            })
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getTarjetas: ' + error.message,
            tarjetas: null
          })
        }
      })
    },
    async processPayment({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          // obtengo el id de pago y la empresa
          let pagoId = datos.idpago
          let empresaCodigo = datos.empresa
          // controlo que no se haya cobrado el boton
          let unBoton = await store.dispatch('botonPago/getUnBotonPago', {empresa: empresaCodigo, key: pagoId})
          if (unBoton.resultado == 0){
            return resolve({
              resultado: 0,
              msj: unBoton.msj,
              datosCobro: null
            })
          }
          if (unBoton.boton.estado == 'APROBADO'){
            return resolve({
              resultado: 2,
              msj: 'El Botón ya se encuentra Cobrado.',
              datosCobro: null
            })
          }
          // armo el importe a cobrar
          let totalAPagar = '0'
          if (parseFloat(datos.total)%1 == 0){
            totalAPagar = parseInt(datos.total).toString() + '00'
          }else{
            totalAPagar = (parseFloat(datos.total)*100)
            totalAPagar = (parseInt(totalAPagar)).toString()
          }
          // armo la estructura para realizar el cobro
          let payment = {
            tarjeta_numero: datos.tarjeta_numero,
            mes_expira: datos.mes_vto,
            anio_expira: datos.anio,
            codigo_seguridad: datos.codigo_seguridad,
            titular: datos.titular,
            tipo_doc: 'dni',
            documento: datos.documento_numero,
            empresa_id: empresaCodigo,
            tipo_pedido: 19, // BOTON DE PAGO COBROS ONLINE
            importe: totalAPagar,
            cuotas: datos.cuotas,
            tarjeta_codigo: datos.forma_pago.TARJETA_CODIGO,
            key_boton: datos.key_boton
          }
          // LLAMADA A LA API DE JARVIS
          let cobroPeticion = await fetch(config.BASE_URL + '/decidir/Pago', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.tokenApi
            },
            body: JSON.stringify(payment)
          })
          let cobroResultado = await cobroPeticion.json()
          return resolve({
            resultado: 1,
            msj: 'OK',
            datosCobro: cobroResultado
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo processPayment: ' + error.message,
            datosCobro: null
          })
        }
      })
    },
    async sendEmailPetition({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let emailPeticion = await fetch(config.BASE_URL + '/adminEmails/setAnEmailPetition', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.tokenApi
            },
            body: JSON.stringify(datos)
          })
          let emailRes = await emailPeticion.json()
          return resolve({
            resultado: 1,
            msj: 'OK',
            peticionEmail: emailRes
          })
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo sendEmailPetition: ' + error.message,
            peticionEmail: null
          })
        }
      })
    },
    async cancelPayment({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          // armo el importe si llegara a tener
          let totalAnular = '0'
          if (convertDecimals(datos.importe) > 0){
            // estan haciendo anulacion parcial
            if (convertDecimals(datos.importe)%1 == 0){
              totalAnular = parseInt(datos.importe).toString() + '00'
            }else{
              totalAnular = (convertDecimals(datos.importe)*100)
              totalAnular = (parseInt(totalAnular)).toString()
            }
          }
          let bodyAnulacion = {
            transaccion_id: datos.id,
            empresa_id: datos.empresa_id,
            importe: totalAnular
          }
          // LLAMADA A LA API PARA ANULAR
          let anulacionPeticion = await fetch(config.BASE_URL + '/decidir/anular', {
            method: 'POST',
            headers: {
            'Content-Type': 'application/json',
            'Authorization': store.state.tokenApi
            },
            body: JSON.stringify(bodyAnulacion)
          })
          let anulacionResultado = await anulacionPeticion.json()
          if (anulacionResultado.exito == 1){
            return resolve({
              resultado: 1,
              msj: 'OK'
            })
          }else{
            return resolve({
              resultado: 0,
              msj: anulacionResultado.mensaje
            })
          }
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo cancelPayment: ' + error.message
          })
        }
      })
    },
    async getComprobante({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          // armo el body a enviar
          let bodyArch = {
            transaccion_id: datos.transaccion_id
          }
          // LLAMADA A LA API PARA ANULAR
          let archivoPeticion = await fetch(config.BASE_URL + '/cobros/getComprobantePdf', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.tokenApi
            },
            body: JSON.stringify(bodyArch)
          })
          let archivo = await archivoPeticion.json()
          return resolve(archivo)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getComprobante: ' + error.message,
            comprobante64: null
          })
        }
      })
    },
    async getPaymentStatus({}, datos){
      return await new Promise(async (resolve, reject) => {
        try{
          let statusPeticion = await fetch(config.BASE_URL + `/cobros/getPaymentStatus?key_firebase=${datos.key_firebase}&empresa_codigo=${datos.empresa_codigo}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': store.state.tokenApi
            }
          })
          let status = await statusPeticion.json()
          return resolve(status)
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo getPaymentStatus: ' + error.message
          })
        }
      })
    }
  }
}