import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import { getAuth, signInWithEmailAndPassword, signOut, onAuthStateChanged } from "firebase/auth"
import { firebase } from '../firebase.js' // no tocar

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Inicio',
    component: () => import('../views/Home.vue'),
    meta: { requireAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login/Login.vue')
  },
  {
    path: '/payment/:empresa/:id',
    name: 'PagoOnline',
    component: () => import('../views/PagoOnline/PagoOnline.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(async (to, from, next) => {
  if (to.matched.some(record => record.meta.requireAuth)){
    if (await is_auth() == true){
      next()
    }else{
      next({ path: '/login' })
    }
  }else{
    next()
  }
  window.scrollTo(0, 0)
})

async function is_auth () {
  let usuario = await store.dispatch('firebaseService/getCurrentUser')
  if (usuario.resultado == 2 || usuario.resultado == 0){
    return false
  }
  return true
}

export default router
