import firebase from 'firebase/compat/app'

import 'firebase/compat/auth'
import 'firebase/compat/database'
import 'firebase/compat/storage'
//import "firebase/compat/analytics"

const firebaseConfig = {
  apiKey: "AIzaSyBQe-regfvNbg9fXZz2I527YzHvGr88Z-4",
  authDomain: "celushop-5a30e.firebaseapp.com",
  databaseURL: "https://celushop-5a30e.firebaseio.com",
  projectId: "celushop-5a30e",
  storageBucket: "celushop-5a30e.appspot.com",
  messagingSenderId: "847120551693",
  appId: "1:847120551693:web:5223653756f7983401aa44",
  measurementId: "G-HMKJG4TXVF"
};
// Initialize Firebase
firebase.initializeApp(firebaseConfig)
//firebase.analytics();

const db  = firebase.database()
const auth = firebase.auth()
const storage = firebase.storage()

export {firebase, db, auth, storage}