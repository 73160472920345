import store from '../store'
import { Encrypt, Decrypt } from "../util/Aes.js"
import config from '../../config'
import { VueEasyJwt } from 'vue-easy-jwt'
const jwt = new VueEasyJwt()

export default{
  namespaced: true,
  state: {

  },
  mutations: {

  },
  actions: {
    async executeLoginApi({}){
      return await new Promise(async (resolve, reject) => {
        try{
          let loguear = 0
          // tengo ya el token?
          if (localStorage.getItem('tokenApi')){
            // ya lo tengo al token, paso a llenar localStorage
            await store.dispatch('loadLocalStorage')
            // controlo a ver si el token es válido
            let invalido = jwt.isExpired(store.state.tokenApi)
            if (invalido == true){
              // quito el token expirado y marco la bandera para hacer la peticion de otro
              localStorage.removeItem('tokenApi')
              // dejo vacio el token de la API
              await store.dispatch('loadLocalStorage')
              loguear = 1
            }
          }else{
            loguear = 1
          }
          // debo loguear?
          if (loguear == 1){
            // hago un nuevo login
            let body = {
              usuario: Encrypt(config.USUARIO_API),
              clave: Encrypt(config.PASSWORD_API)
            }
            let loginPedido = await fetch(config.BASE_URL + '/user/login', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Methods': 'DELETE, POST, GET, OPTIONS',
                'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With'
              },
              body: JSON.stringify(body)
            })
            let login = await loginPedido.json()
            // tuve exito al hacer Login?
            if (parseInt(login.exito) === 1){
              // guardo el token en localstorage
              localStorage.setItem('tokenApi', JSON.stringify({tokenJarvis: login.long_token}))
              // guardo el token en el store del proyecto
              await store.dispatch('loadLocalStorage')
              return resolve({
                resultado: 1,
                msj:'OK'
              })
            }else{
              return resolve({
                resultado: 0,
                msj:'No se pudo hacer Login en la Api.'
              })
            }
          }else{
            // ya tengo token y es válido
            return resolve({
              resultado: 1,
              msj:'OK'
            })
          }
        }catch(error){
          return resolve({
            resultado: 0,
            msj: 'Ocurrio un problema al ejecutar el metodo executeLoginApi: ' + error.message
          })
        }
      })
    }
  }
}