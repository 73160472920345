<template>
  <v-dialog
    v-model="$store.state.modalCarga"
    width="300"
    persistent
  >
    <v-card
      color="primary"
      dark
    >
      <v-card-text>
        Por favor espere
        <v-progress-linear
          color="white"
          class="mb-0"
          indeterminate
        ></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {

}
</script>

<style>

</style>