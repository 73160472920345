import Vue from 'vue'
import Vuex from 'vuex'
import { getDatabase, ref, onValue} from 'firebase/database'
import { getAuth, signInWithEmailAndPassword, signOut, onAuthStateChanged } from "firebase/auth"
import { firebase } from '../firebase.js' // no tocar
import { Encrypt, Decrypt } from '@/util/Aes.js'
import router from '../router'

// modulos
import usuarios from '../modules/usuarios'
import cobros from '../modules/cobros'
import botonPago from '../modules/botonPago'
import firebaseService from '../modules/firebaseService'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    modalCarga: false,
    snackBarActivo: false,
    colorSnackBar: 'success',
    msjSnackBar: '',
    respEmpresas: {},
    tokenFirebase: '',
    respUsuario: {},
    respLogOut: {},
    tokenApi: '',
    img_perfil: '',
    username: '',
    dialogAviso: {
      activo: false,
      titulo: '',
      texto: '',
      icono: '',
      color: ''
    }
  },
  mutations: {
    setEmpresas(state, payload){
      state.respEmpresas = payload
    },
    setUsuario(state, payload){
      state.tokenFirebase = payload.accessToken
      localStorage.setItem('empresa', JSON.stringify(payload.empresa))
      state.respUsuario = payload
    },
    setLogOut(state, payload){
      // quito la empresa de localStorage
      if (localStorage.getItem('empresa')){
        localStorage.removeItem('empresa')
      }
      state.respLogOut = payload
      state.modalCarga = false
    },
    setmsjSnack(state, payload){
      state.msjSnackBar = payload
    },
    setColorSnack(state, payload){
      state.colorSnackBar = payload
    },
    setActivoSnack(state, payload){
      state.snackBarActivo = payload
    },
    setLocalStorage(state){
      if (localStorage.getItem('tokenApi')){
        state.tokenApi = JSON.parse(localStorage.getItem('tokenApi')).tokenJarvis
      }else{
        state.tokenApi = ''
      }
    },
    setModalAviso(state, payload){
      state.dialogAviso.titulo = payload.titulo
      state.dialogAviso.texto = payload.texto
      state.dialogAviso.icono = payload.icono
      state.dialogAviso.color = payload.color
      state.dialogAviso.activo = true
    }
  },
  actions: {
    async loadLocalStorage({commit}){
      commit('setLocalStorage')
    },
    async getFirebaseEmpresas({commit}){
      let empresasRespuesta = {}
      try{
        let data = []
        let listado = []
        let db = getDatabase()
        let empresasPeticion = ref(db, 'empresas')
        onValue(empresasPeticion, (snapshot) => {
          data = snapshot.val()
          for (let id in data){
            if (data[id].inhabilitado == 0){
              let unObj = {
                empresa_codigo: id,
                abreviacion: data[id].abreviacion,
                cuit: data[id].cuit,
                decidir: data[id].decidir,
                ingresa_financicion: data[id].ingresa_financicion,
                logo: data[id].logo,
                nombre: data[id].nombre,
                redireccion: data[id].redireccion
              }
              listado.push(unObj)
            }
          }
          empresasRespuesta.resultado = 1
          empresasRespuesta.msj = 'OK'
          empresasRespuesta.empresas = listado
          commit('setEmpresas', empresasRespuesta)
        })
      }catch(error){
        empresasRespuesta.resultado = 0
        empresasRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo getFirebaseEmpresas: ' + error.message
        empresasRespuesta.empresas = null
        commit('setEmpresas', empresasRespuesta)
      }
    },
    async loginFirebase({commit}, datos){
      let loginRespuesta = {}
      try{
        let auth = getAuth()
        let persistencia = await this.dispatch('firebaseService/setPersistenceUser')
        if (persistencia.resultado == 0){
          this.dispatch('logOutFirebase')
          return
        }
        signInWithEmailAndPassword(auth, datos.user, datos.password)
          .then((userCredential) => {
            // correctamente logueado, pero corresponde que vea lo de la empresa?
            let uid = userCredential.user.uid
            let data = []
            let db = getDatabase()
            let usuariosPeticion = ref(db, 'usuarios/' + uid)
            onValue(usuariosPeticion, (snapshot) => {
              data = snapshot.val()
              if (data.hasOwnProperty('empresa')){
                let existe = 0
                for (let id in data.empresa){
                  if (data.empresa[id].codigo == datos.empresa){
                    existe = 1
                  }
                }
                if (existe == 0){
                  // no habilitado
                  signOut(auth)
                    .then(() => {
                      loginRespuesta.resultado = 2
                      loginRespuesta.msj = 'El usuario no está habilitado en la Empresa seleccionada.'
                      loginRespuesta.errorCode = null
                      loginRespuesta.accessToken = null
                      loginRespuesta.displayName = null
                      loginRespuesta.email = null
                      loginRespuesta.uid = null
                      loginRespuesta.empresa = datos.empresa
                    })
                    .catch(() => {
                      loginRespuesta.resultado = 2
                      loginRespuesta.msj = 'El usuario no está habilitado en la Empresa seleccionada.'
                      loginRespuesta.errorCode = null
                      loginRespuesta.accessToken = null
                      loginRespuesta.displayName = null
                      loginRespuesta.email = null
                      loginRespuesta.uid = null
                      loginRespuesta.empresa = datos.empresa
                    })
                }else{
                  // habilitado
                  loginRespuesta.resultado = 1
                  loginRespuesta.msj = 'OK'
                  loginRespuesta.errorCode = null
                  loginRespuesta.accessToken = userCredential.user.accessToken
                  loginRespuesta.displayName = userCredential.user.displayName
                  loginRespuesta.email = userCredential.user.email
                  loginRespuesta.uid = userCredential.user.uid
                  loginRespuesta.empresa = datos.empresa
                }
                commit('setUsuario', loginRespuesta)
              }else{
                // desloguear
                signOut(auth)
                  .then(() => {
                    loginRespuesta.resultado = 2
                    loginRespuesta.msj = 'El usuario no está habilitado en la Empresa seleccionada.'
                    loginRespuesta.errorCode = null
                    loginRespuesta.accessToken = null
                    loginRespuesta.displayName = null
                    loginRespuesta.email = null
                    loginRespuesta.uid = null
                    loginRespuesta.empresa = datos.empresa
                  })
                  .catch(() => {
                    loginRespuesta.resultado = 2
                    loginRespuesta.msj = 'El usuario no está habilitado en la Empresa seleccionada.'
                    loginRespuesta.errorCode = null
                    loginRespuesta.accessToken = null
                    loginRespuesta.displayName = null
                    loginRespuesta.email = null
                    loginRespuesta.uid = null
                    loginRespuesta.empresa = datos.empresa
                  })
              }
              commit('setUsuario', loginRespuesta)
            })
          })
          .catch((error) => {
            loginRespuesta.resultado = 0
            loginRespuesta.msj = error.message
            loginRespuesta.errorCode = error.code
            loginRespuesta.displayName = null
            loginRespuesta.email = null
            loginRespuesta.uid = null
            loginRespuesta.empresa = datos.empresa
            commit('setUsuario', loginRespuesta)
          })
      }catch(error){
        loginRespuesta.resultado = 0
        loginRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo loginFirebase: ' + error.message
        loginRespuesta.errorCode = null
        loginRespuesta.displayName = null
        loginRespuesta.email = null
        loginRespuesta.uid = null
        loginRespuesta.empresa = datos.empresa
        commit('setUsuario', loginRespuesta)
      }
    },
    async logOutFirebase({commit}, datos){
      let logOutRespuesta = {}
      try{
        let msjAlDesloguear = 0
        if (Object.keys(datos).length > 0){
          msjAlDesloguear = 1
        }
        let logOut = {}
        let currentUser = await this.dispatch('firebaseService/getCurrentUser')
        if (currentUser.resultado == 1){
          // usuario logueado
          logOut = await this.dispatch('firebaseService/executeLogOut', {auth: currentUser.auth})
        }else if (currentUser.resultado == 0){
          // error, intentar desloguear y redireccionar
          logOut = await this.dispatch('firebaseService/executeLogOut', {})
        }
        if (msjAlDesloguear = 1){
          commit('setmsjSnack', datos.msj)
          commit('setColorSnack', datos.color)
          commit('setActivoSnack', datos.activo)
          router.push({name: 'Login', params: {sesionExpirada: datos.sesionExpirada}})
        }else{
          router.push({name: 'Login'})
        }
        logOutRespuesta.resultado = 1
        logOutRespuesta.msj = 'OK'
        commit('setLogOut', logOutRespuesta)
      }catch(error){
        logOutRespuesta.resultado = 0
        logOutRespuesta.msj = 'Ocurrio un problema al ejecutar el metodo logOutFirebase: ' + error.message
        commit('setLogOut', logOutRespuesta)
      }
    },
    async executeModalAviso({commit}, datos){
      commit('setModalAviso', datos)
    }
  },
  modules: {
    usuarios,
    cobros,
    botonPago,
    firebaseService
  }
})
