<template>
  <v-dialog
    v-model="$store.state.dialogAviso.activo"
    max-width="60%"
  >
    <v-card flat>
      <v-card-title class="pa-0">
        <v-toolbar :color="$store.state.dialogAviso.color">
          <v-toolbar-title class="white--text">
            <v-icon class="ma-2" color="white" v-if="$store.state.dialogAviso.icono.toString().length > 0">{{$store.state.dialogAviso.icono}}</v-icon> {{$store.state.dialogAviso.titulo}}
          </v-toolbar-title>
        </v-toolbar>
      </v-card-title>
      <v-card-text class="mt-2">
        <v-row :no-gutters="$vuetify.breakpoint.xs">
          <v-col cols="12">
            <p style="font-size: 20px" class="text-center"><strong>{{$store.state.dialogAviso.texto}}</strong></p>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="d-flex justify-center pb-4">
        <v-btn color="success" @click="$store.state.dialogAviso.activo = false">Aceptar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  
}
</script>

<style>

</style>