<template>
  <div>
    <v-navigation-drawer
      v-model="drawer"
      color="secondary"
      style="z-index: 3"
      height="100%"
      width=240
      :absolute="$vuetify.breakpoint.mdAndUp"
      :expand-on-hover="expand"
      :mobile-breakpoint="960"
      dark
      app
    >
      <v-list class="py-0">
        <v-list-item class="px-2 text-left">
          <v-list-item-avatar>
            <v-img
              :src="$store.state.img_perfil === '' ? require('../../assets/bb.webp') : `data:image/jpeg;base64,${$store.state.img_perfil}`"
            ></v-img>
          </v-list-item-avatar>
          <div>
            <v-list-item-title>
              {{ $store.state.username }}
            </v-list-item-title>
            <v-list-item-subtitle class="caption mt-1">
              <v-icon
                color="success"
                class="mr-1"
                x-small
              >
                fas fa-circle
              </v-icon>
              Online
            </v-list-item-subtitle>
          </div>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>
      <v-list
        class="text-left pa-2"
        dense
        nav
      >
        <template v-for="(item, index) in items">
          <v-list-group
            v-if="item.children"
            :key="index"
            color="white"
            no-action
          >
            <v-icon slot="appendIcon" small>fas fa-chevron-down</v-icon>
            <template v-slot:activator>
              <v-icon
                slot="prependIcon"
                class="ml-1 py-3"
                style="width: 15px"
                small
              >
                {{ item.icon }}
              </v-icon>
              <v-list-item-content>
                <v-list-item-title class="ml-6">
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item-content>
            </template>

            <template v-for="(child, i) in item.children">
              <v-list-item
                :key="i"
                class="pl-6"
                :to="child.href"
                link
              >
                <v-list-item-icon class="mr-4">
                  <v-icon small>
                    {{ child.icon }}
                  </v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                  {{ child.text }}
                </v-list-item-title>
              </v-list-item>
            </template>
          </v-list-group>

          <v-list-item
            v-else
            :key="index"
            :to="item.href"
            link
          >
            <v-list-item-icon>
              <v-icon
                class="ml-1"
                style="width: 15px"
                small
              >
                {{ item.icon }}
              </v-icon>
            </v-list-item-icon>
            <v-list-item-content class="ml-n3">
              <v-list-item-title>
                {{ item.text }}
              </v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
    </v-navigation-drawer>
    <appBar
      :p_expand.sync="expand"
      :p_drawer.sync="drawer"
    />
  </div>
</template>

<script>
  import appBar from '../generales/appBar.vue'
export default {
  data() {
    return {
      drawer: true,
      expand: false,
      items: [
          { text: 'Links', icon: 'fas fa-link', href: '/' }
        ],
    }
  },
  components: {
    appBar,
  },
  created () {
    if (this.$vuetify.breakpoint.smAndDown) {
      this.drawer = false
    }
  },
  watch: {
    '$vuetify.breakpoint.smAndDown' (val) {
      if (val) {
        this.expand = false
        this.drawer = false
      } else {
        this.expand = false
        this.drawer = true
      }
    }
  },
}
</script>

<style>

</style>